import React, { useEffect, useRef, useState } from 'react';
import './App.css';

import QRCode from 'easyqrcodejs';

interface QrCodeOptions {
	text: string
	size: number
	fgColor: string
	bgColor: string
	correctLevel: number
	logo: FileList | null
	logoSize: number
	setQr: (arg0: QRCode)=>void
}

const QrCode = ({text, size, fgColor, bgColor, correctLevel, logo, logoSize, setQr}: QrCodeOptions) => {
	const code = useRef<HTMLDivElement>(null);
	let qrCode: QRCode | null = null;

	// unfortunately QRCode doesn't export correct level as a type, so this will do
	const translateCorrectLevel = (correctLevel: number) => {
		switch (correctLevel) {
			case 0: return 1; // low
			case 1: return 0; // medium
			case 2: return 3; // quartile
			case 3: return 2; // high
			default: return 0;
		}
	};

	useEffect(() => {
		// This function clears the QR code from the container
		const clearQRCode = () => {
			if (code.current) {
				code.current.innerHTML = ''; // Clear the container
			}
		};

		// Create QR code
		const generateQRCode = () => {
			if (code.current) {
				let logoURL = null;
				if (logo && logo.length > 0) {
					logoURL = URL.createObjectURL(logo[0]);
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
				let qrCode = new QRCode(code.current, {
					text: text,
					height: size,
					width: size,
					colorDark: fgColor,
					colorLight: bgColor,
					correctLevel: translateCorrectLevel(correctLevel),
					logo: logoURL,
					logoWidth: logoSize,
					logoHeight: logoSize
				});

				setQr(qrCode);
			}
		};

		clearQRCode();
		generateQRCode();

		// Cleanup function to clear QR code on component unmount or before re-render
		return clearQRCode;
	}, [text, size, fgColor, bgColor, correctLevel, logo, logoSize]);

	return (
		<div ref={code}/>
	);
}

function App() {
	const [qrText, setQrText] = useState<string>('simplerqr.com');

	const [qrFgColor, setQrFgColor] = useState<string>('#000000');
	const [rawQrFgColor, setRawQrFgColor]= useState<string>('#000000');

	const [qrBgColor, setQrBgColor] = useState<string>('#ffffff');
	const [rawQrBgColor, setRawQrBgColor]= useState<string>('#ffffff');

	const [qrSize, setQrSize] = useState<number>(350);
	const [correctionLevel, setCorrectionLevel] = useState<number>(0);

	const [logoFile, setLogoFile] = useState<FileList | null>(null);
	const [logoSize, setLogoSize] = useState<number>(50);

	const [qr, setQR] = useState<QRCode | null>(null);

	const onQrTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQrText(e.target.value);
	}

	const onFgColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let input = e.target.value;
		const hexRegex = /^#[0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?([0-9A-Fa-f]{2})?$/;
		if(!input.startsWith('#')) {
			input = "#" + input;
		}
		setRawQrFgColor(input);
		if (input.startsWith('#') && input.length >= 4 && input.length <= 9) {
			if (hexRegex.test(input)) {
				setQrFgColor(input);  // Update the last valid color only when a valid hex code is entered
			}
		}
	}

	const onBgColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let input = e.target.value;
		const hexRegex = /^#[0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?([0-9A-Fa-f]{2})?$/;
		if(!input.startsWith('#')) {
			input = "#" + input;
		}
		setRawQrBgColor(input);
		if (input.startsWith('#') && input.length >= 4 && input.length <= 9) {
			if (hexRegex.test(input)) {
				setQrBgColor(input);  // Update the last valid color only when a valid hex code is entered
			}
		}
	}

	const onCorrectionLevelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.target.valueAsNumber;
		if( !(input < 0) || !(input > 3) ) {
			setCorrectionLevel(input);
		}
	}

	const onQrSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQrSize(e.target.valueAsNumber);
	}

	const onLogoFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLogoFile(e.target.files);
	}

	const onLogoSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.target.valueAsNumber;
		setLogoSize(input);
	}

	const downloadQr = () => {
		if(qr != null) {
			qr.download(`qr-${new Date().getTime()}`);
		}
	}

	return (
		<div className='App'>
			<div className='InputsContainer'>
				<div className='InputOption'>
					<div className='InputTitle'>QR Text</div>
					<input className='TextInput' type='text' onChange={onQrTextChange} value={qrText}></input>
				</div>
				<div className='InputOption'>
					<div className='InputTitle'>Code Color</div>
					<input className='ColorInput' type='text' onChange={onFgColorChange} value={rawQrFgColor}></input>
				</div>
				<div className='InputOption'>
					<div className='InputTitle'>Background Color</div>
					<input className='ColorInput' type='text' onChange={onBgColorChange} value={rawQrBgColor}></input>
				</div>
				<div className='InputOption'>
					<div className='InputTitle'>QR Size (in pixels)</div>
					<input className='NumberInput' type='number' step={10} min={0} max={5000} onChange={onQrSizeChange} value={qrSize}></input>
				</div>
				<div className='InputOption'>
					<div className='InputTitle'>Correction: 0=Low, 3=High</div>
					<input className='NumberInput' type='number' step={1} min={0} max={3} onChange={onCorrectionLevelChange} value={correctionLevel}></input>
				</div>
				<div className='InputOption'>
					<div className='InputTitle'>Optional Logo</div>
					<input className='FileInput' type='file' onChange={onLogoFileChange}></input>
				</div>
				<div className='InputOption'>
					<div className='InputTitle'>Logo Size</div>
					<input className='NumberInput' type='number' step={10} min={0} max={5000} onChange={onLogoSizeChange} value={logoSize}></input>
				</div>
			</div>
			<div className='QrCodeContainer'>
				<QrCode text={qrText} fgColor={qrFgColor} bgColor={qrBgColor} size={qrSize} correctLevel={correctionLevel} logo={logoFile} logoSize={logoSize} setQr={setQR}/>
				<div className='QrDownloadButton' onClick={downloadQr}>Download</div>
			</div>
		</div>
	);
}

export default App;
